import { getAllPlans, getAllPlansCategory } from 'apis/plans';
import { fetchReligions, getCastesByReligion } from 'apis/religionAndCaste';
import { FILTER_OPTIONS_MAP, USERFILTERKEYS } from "constant/filter";
import React, { useEffect, useState } from "react";
import { capitalize } from "shared/textConverter";
import { addLabelAndValueInData } from "utils/dataConversion";
import FilterCard from "./card";


function Index({ handleFilterData, handleShowFilter }) {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [filterOptionsMap, setFilterOptionsMap] = useState(FILTER_OPTIONS_MAP || {})
    const [filterKeys, setFilterKeys] = useState([]);
    const [search, setSearch] = useState('');


    // Function to handle adding a new filter key
    const addNewFilter = (key) => {
        if (filterKeys.some(filter => filter.key === key)) {
            setFilterKeys(prevKeys => prevKeys.filter(filter => filter.key !== key));
        } else {
            if (key === "religion_id") {
                getAllReligions();
            } else if (key === 'plan_categories') {
                getAllPlanCategory();
            } else if (key === 'plans') {
                fetchAllPlans();
            }

            setFilterKeys(prevKeys => [
                ...prevKeys,
                { key, filterType: 'include', values: [] }
            ]);
        }
    };

    // Function to handle filter type selection
    const handleFilterTypeChange = (key, type) => {
        setFilterKeys(prevKeys =>
            prevKeys.map(filter =>
                filter.key === key ? { ...filter, filterType: type } : filter
            )
        );
    };

    // Function to handle value selection
    const handleValueChange = (key, values) => {
        if (key === 'religion_id') {
            fetchCasteByReligionId(values)
        }
        setFilterKeys(prevKeys =>
            prevKeys.map(filter =>
                filter.key === key ? { ...filter, values } : filter
            )
        );
    };

    // Function to remove a filter
    const handleRemoveFilter = (key) => {
        setFilterKeys(prevKeys => prevKeys.filter(filter => filter.key !== key));
    };


    // API CALLS
    const getAllReligions = async () => {
        const res = await fetchReligions();
        if (res) {
            const data = addLabelAndValueInData(res?.data, 'name', 'id');
            setFilterOptionsMap(prevMap => ({
                ...prevMap,
                religion_id: data
            }));
        }
    };

    const fetchCasteByReligionId = async (ids) => {
        let allCastOptions = [];
        for (const id of ids) {
            const res = await getCastesByReligion(id);
            allCastOptions = [...allCastOptions, ...res?.data];
        }
        const data = addLabelAndValueInData(allCastOptions, 'name', 'id')

        setFilterOptionsMap(prev => ({
            ...prev,
            caste_id: data
        }));
    }


    const getAllPlanCategory = async () => {
        const res = await getAllPlansCategory();
        if (res) {
            const data = addLabelAndValueInData(res?.data, 'name', 'id');
            setFilterOptionsMap(prevMap => ({
                ...prevMap,
                plan_categories: data
            }));
        }
    };

    const fetchAllPlans = async () => {
        const res = await getAllPlans();
        if (res) {
            const data = addLabelAndValueInData(res?.data, 'name', 'id');
            setFilterOptionsMap(prevMap => ({
                ...prevMap,
                plans: data
            }));
        }
    }

    useEffect(() => {
        const savedFilters = sessionStorage.getItem('newUserFilter');

        if (savedFilters) {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const { page, limit, search, ...otherFilters } = JSON.parse(savedFilters);

            setSearch(search || null);

            const filtersArray = Object.values(otherFilters).map(({ key, filterType, values }) => ({
                key,
                filterType,
                values,
            }));

            setFilterKeys(filtersArray);

            // Check if certain keys exist and call corresponding functions
            filtersArray.forEach(({ key }) => {
                if (key === 'plans') {
                    fetchAllPlans();
                }

                if (key === 'religion_id') {
                    getAllReligions();
                }
                if (key === 'caste_id') {
                    const religionFilter = filtersArray.find(filter => filter.key === 'religion_id');
                    if (religionFilter) {
                        fetchCasteByReligionId(religionFilter.values);
                    }
                }
            });
        }
    }, []);



    const handleFilter = async () => {
        const data = { ...filterKeys, search }
        sessionStorage.setItem('newUserFilter', JSON.stringify(data));
        handleFilterData(data)
    }

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="w-full h-full max-h-screen p-3 overflow-y-auto border border-gray-300">
                <div className="relative flex items-center justify-between">
                    <div className="text-lg font-semibold">Filter</div>
                    <div
                        onClick={() => setDropdownVisible(!isDropdownVisible)}
                        className="px-2 text-lg font-semibold text-white bg-gray-800 cursor-pointer"
                    >
                        +
                    </div>
                </div>

                {/* Dropdown to select new filter key */}
                {isDropdownVisible && (
                    <div className="absolute w-[285px] p-2 mt-2 z-50">
                        <div className="flex flex-col bg-white max-h-[400px] overflow-y-auto">
                            {USERFILTERKEYS?.map((item, index) => {
                                const isActive = filterKeys.some(filter => filter.key === item.key);
                                return (
                                    <label
                                        key={index}
                                        onClick={() => addNewFilter(item.key)}
                                        className={`text-gray-700 font-medium p-2 cursor-pointer ${isActive ? 'bg-gray-300' : 'hover:bg-gray-200'
                                            }`}
                                    >
                                        {item.label}
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                )}

                <input className="w-full p-2 mt-5 bg-white border border-gray-500"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                />

                {/* Render each filter card */}
                <div className="mt-5">
                    {filterKeys?.map((item, index) => (
                        <div key={index} className="mt-2">
                            <FilterCard
                                label={capitalize(item?.key)}
                                handleFilterType={(type) => handleFilterTypeChange(item?.key, type)}
                                handleValueChange={(values) => handleValueChange(item?.key, values)}
                                selectedFilterType={item.filterType}
                                selectedValue={item.values}
                                handleOnClose={() => handleRemoveFilter(item?.key)}
                                inputType={USERFILTERKEYS?.find(filter => filter?.key == item?.key)?.inputType}
                                valuesOption={filterOptionsMap?.[item?.key]}
                            />
                        </div>
                    ))}
                </div>

                <div className="flex justify-center gap-4 p-4 mt-auto">
                    <button
                        onClick={handleFilter}
                        className="p-4 font-semibold text-white transition duration-300 bg-green-500 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                        Submit
                    </button>
                    <button
                        onClick={handleShowFilter}
                        className="p-4 font-semibold text-white transition duration-300 bg-red-500 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                    >
                        Close
                    </button>
                </div>


            </div>
        </div>
    );
}

export default Index;
