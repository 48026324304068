
import { auth } from "apis";

export const getDashboardOverview = async (data) => {
    const res = await auth({
        method: "GET",
        url: `/admin/dashboard-counts`,
        data,
    });
    return res;
}

export const getFastSmsWalletBalance = async () => {
    const res = await auth({
        method: "GET",
        url: `/admin/fastsms/balance`,
    });
    return res;
}

export const fetchRazorpayWebhookStatus = async () => {
    const res = await auth({
        method: "GET",
        url: `/admin/razorpay/webhook`,
    });
    return res;
}

export const updateRazorpayWebhookStatus = async () => {
    const res = await auth({
        method: "PUT",
        url: `/admin/razorpay/webhook`,
    });
    return res;
}
