import React, { useEffect, useState } from 'react';

// Components
import { fetchBlogById } from 'apis/blog';
import ArticleContent from './components/articleContent';
import TableOfContent from './components/tableOfContent';
import { useParams } from 'react-router-dom';
import { decodeFromBase64 } from 'utils/encodeAndDecode';
import { getTheApp } from 'utils/getTheApp';



function Index() {

    const { id } = useParams();
    const [blogData, setBlogData] = useState()
    const [toc, setToc] = useState([])


    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = Array.from(doc.querySelectorAll('h2')).map((heading, index) => ({
            id: `heading-${index}`,
            text: heading.textContent,
        }));

        return headings;
    };

    const getBlogById = async () => {
        const decodedId = decodeFromBase64(id)
        const res = await fetchBlogById(decodedId)
        if (res?.success) {
            setBlogData(res?.data)
            const toc = extractHeadings(res?.data?.blog_content);
            setToc(toc);
        }
    }


    useEffect(() => {
        if (id) getBlogById()
    }, [id])

    const handleOnClickImage = (value) => {
        if (value === 'app_link') {
            getTheApp()
        } else {
            window.open(value, "_blank");
        }
    }

    return (

        <div className="min-h-screen bg-white">
            <div className="flex flex-col lg:flex-row pt-[20%] md:p-8 w-[95%] mx-auto">
                {/* Left Sidebar */}
                <div className="w-full lg:w-1/4">
                    <div className="sticky top-[7%]">
                        <TableOfContent toc={toc} />
                    </div>
                </div>

                {/* Main Content */}
                <div className="w-full lg:w-2/4">
                    <ArticleContent blogData={blogData} />
                </div>

                {/* Right Sidebar */}
                <div className="w-full lg:w-1/4">
                    <div className="w-full p-4 text-black sticky top-[7%]">
                        <img
                            src={blogData?.advertisement_right_side?.image_url}
                            alt={blogData?.advertisement_right_side?.alt_text}
                            onClick={() => handleOnClickImage(blogData?.advertisement_right_side?.link)}
                            className="object-cover w-auto h-auto cursor-pointer"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;
