import { auth } from "..";
import qs from 'qs';


export const getAllUsers = async (filter) => {
    const params = new URLSearchParams();
      for (const key in filter) {
      if (filter[key] !== null && filter[key] !== undefined) {
        params.append(key, filter[key].toString());
      }
  }
      const url = `/admin/users?${params.toString()}`;
      const res = await auth({
      method: "GET",
      url: url,
    });
  
    return res;
};
  

export const getAllUsersV2 = async (filter) => {
  // Serialize the filter object to a query string
  const queryString = qs.stringify(filter, { arrayFormat: 'brackets' });
  const url = `/admin/users/v2?${queryString}`;
  const res = await auth({
    method: "GET",
    url: url,
  });

  return res;
};

export const getAllUsersBasedOnActivity = async (filter) => {
  // Serialize the filter object to a query string
  const queryString = qs.stringify(filter, { arrayFormat: 'brackets' });
  const url = `/admin/users/activities?${queryString}`;
  const res = await auth({
    method: "GET",
    url: url,
  });

  return res;
};
  

// ========== User Details View and Edit
// GET AND UPDATE USER Details
export const getUserDetails = async (id) => {
  const res = await auth({
    method: 'GET',
    url:`/admin/user/${id}`,
  })
  return res
}

export const updateUserDetails = async (id,data) => {
  const res = await auth({
    method: 'PUT',
    url: `/admin/user/${id}`,
    data
  })
  return res
}
// GET AND UPDATE USER FILTER
export const getUserFilter = async (id) => {
  const res = await auth({
    method: 'GET',
    url:`/admin/user/filters/${id}`,
  })
  return res
}

export const updateUserBasicAndAdvanceFilter = async (id,data) => {
  const res = await auth({
    method: 'PUT',
    url: `/admin/user/filters/${id}`,
    data,
  })
  return res
}

// view and edit user Basic Info
export const getUserBasicDetails = async(id)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/basic/${id}`,
  });
  return res
}
export const updateUserBasicDetails = async(id,data)=>{
  const res = await auth({
    method: "POST",
    url: `/admin/user/basic/${id}`,
    data
  });
  return res
};

export const changeUserAccountStatus = async (id) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/status/${id}`,
  });
  return res;
}

export const unVerifyUser = async (id,data) => {
  const res = await auth({
    method: "PUT",
    url: `/verification/update/status/${id}`,
    data
  });
  return res;
}

// view and edit user profile details
export const getUserProfileDetails = async(id)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/profile/${id}`,
  });
  return res
}

export const updateUserProfileDetails = async (id, data) => {
  const res = await auth({
    method: "POST",
    url: `/admin/user/profile/${id}`,
    data
  });
  return res
}

// view and edit user basic Filter Details
export const getUserBasicFilter = async(id)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/filters/basic/${id}`,
  });
  return res
}

export const updateBasicFilter = async (id, data) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/filter/basic/${id}`,
    data
  });
  return res
}

// view and edit user advanced Filter Details
export const getUserAdvancedFilter = async(id)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/filters/advanced/${id}`,
  });
  return res
}

export const updateAdvancedFilter = async (id, data) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/filter/advanced/${id}`,
    data
  });
  return res
}

// view and edit user Privacy settings
export const getUserPrivacySettings = async(id)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/privacy/${id}`,
  });
  return res
}

export const updateUserPrivacySettings = async (id, data) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/privacy/${id}`,
    data
  });
  return res
}

// view and edit user Connection
export const getUserFriendsList = async(id,page)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/friends/${id}?page=${page}&limit=10`,
  });
  return res
}

export const getUserConnectionList = async(id,page,type)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/connections/${id}?page=${page}&limit=10&type=${type}`,
  });
  return res
}

export const getUserQrConnectionList = async(id,page,type)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/friends/qr/${id}?page=${page}&limit=10&type=${type}`,
  });
  return res
}

export const unMatchUserFriendList = async (data) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/unmatch`,
    data
  });
  return res
}

export const deleteUserConnection = async(id,memberId)=>{
  const res = await auth({
    method: "DELETE",
    url: `/admin/user/connections/${id}/${memberId}`,
  });
  return res
}

export const getSkippedUserList = async(id,page)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/users/skip/${id}?page=${page}&limit=10`,
  });
  return res
}

export const removeSkippedUserList = async(id,removeAll=false)=>{
  const res = await auth({
    method: "PUT",
    url: `/admin/user/skip/${id}?removeAll=${removeAll}`,
  });
  return res
}

// HandPicked

export const getHandPickedUserList = async(id,page)=>{
  const res = await auth({
    method: "GET",
    url: `/handpicked/list/${id}?page=${page}&limit=10`,
  });
  return res
}

export const checkEligibleForHandPick = async(id,handpickId)=>{
  const res = await auth({
    method: "GET",
    url: `/handpicked/eligibility/${id}?handpickId=${handpickId}`,
  });
  return res
}
export const assignHandPickMatches = async(id,data)=>{
  const res = await auth({
    method: "POST",
    url: `/handpicked/${id}`,
    data
  });
  return res
}

export const removeHandPickMatches = async(id)=>{
  const res = await auth({
    method: "DELETE",
    url: `/handpicked/${id}`,
  });
  return res
}

// view and edit user Events

export const getUserEventList = async(id,page)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/events/booked/${id}?page=${page}&limit=10`,
  });
  return res
}

export const getUserEventHistory = async(id,page,type)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/events/history/${id}?page=${page}&limit=10&type=${type}`,
  });
  return res
}

// View and Edit user wallet details
export const getUserCardDetails = async(id)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/wallet/card/${id}`,
  });
  return res
}

export const getUserWalletTransaction = async(id,page)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/wallet/transactions/${id}?page=${page}&limit=10`,
  });
  return res
}

export const getUserPlanDetails = async(id)=>{
  const res = await auth({
    method: "GET",
    url: `/admin/user/wallet/plan/${id}`,
  });
  return res
}

export const updateUserPlan = async (id, data) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/plan/${id}`,
    data
  });
  return res
}

export const addCoins = async (id, data) => {
  const res = await auth({
    method: "POST",
    url: `/admin/user/wallet/recharge/${id}`,
    data
  });
  return res
}


export const getReferredUsers = async (id, page) => {
  const res = await auth({
    method: "GET",
    url: `/admin/user/referrals/${id}?page=${page}&limit=10`,
  });
  return res
}

export const getUserActivity = async (id, page) => {
  const res = await auth({
    method: "GET",
    url: `/admin/user/activities/${id}?page=${page}&limit=10`,
  });
  return res
}

export const uploadImage = async (id,data) => {
  const res = await auth({
    method: "POST",
    url: `/admin/user/image/${id}`,
    data,
    options: {
          headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
          },
      },
  });
  return res
};

export const deleteImage = async ( id,index) => {
  const res = await auth({
    method: "DELETE",
    url:`/admin/user/image/${id}?index=${index}`,
    options: {
          headers: {
              Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
          },
      },
  });
  return res
};

// Deleted Users


export const getAllAccountDeletedUsers = async (page,status,rowsPerPage) => {
  const res = await auth({
    method: "GET",
    url: `/admin/users/delete/requestes?page=${page}&limit=${rowsPerPage}&status=${status}`,
  });
  return res
}

export const updateUserAccountStatus = async (id,data) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/delete/request/${id}`,
    data
  });
  return res
}

// Create User
export const createUser = async (data) => {
  const options = {
    headers: {
      content_type: "multipart/form-data",
                Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`
    }
}
  const res = await auth({
    method: "POST",
    url: `/admin/user`,
    data,
    options
  });
  return res
}


export const fetchUserDetails = async (id) => {
  const res = await auth({
    method: "GET",
    url: `/admin/users/preview/${id}`,
  });
  return res
}

// Matches
export const fetchMatches = async (id) => {
  const res = await auth({
    method: "GET",
    url: `/admin/user/matches/${id}`,
  });
  return res
}

export const removeMatches = async (id,memberId,userId) => {
  const res = await auth({
    method: "PUT",
    url: `/admin/user/remove/${id}?memberId=${memberId}&userId=${userId}`,
  });
  return res
}

export const assignPersonalMatches = async (id) => {
  const res = await auth({
    method: "POST",
    url: `/admin/user/matches/suggest/${id}`,
  });
  return res
}
