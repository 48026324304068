import { createNewPlanCategory, getAllPlansCategory, updatePlanCategory } from 'apis/plans'
import { ManagementSecMainHeader, TableWithoutPagination } from 'components'
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { formatDateToDDMMYY } from 'shared/dates'


const CreateAndEditPlanCategory = lazy(() => import("./createAndEditPlanCategory"))


function Index() {

    const [planCategory, setPlanCategory] = useState()
    const [selectedPlanDataCategory, setSelectedPlanDataCategory] = useState()
    const [showAddAndEditPlanCategory, setShowAddAndEditPlanCategory] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const fetchAllPlansCategory = async () => {
        const res = await getAllPlansCategory()
        setPlanCategory(res?.data)
    }

    useEffect(() => {
        fetchAllPlansCategory()
    }, [])

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Plan Name', accessor: 'name', maxWidth: 100 },

        { Header: 'Date', accessor: (row) => formatDateToDDMMYY(row.createdAt) },

        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <span className={`text-white p-2 rounded-3xl ${value ? 'bg-green-500' : 'bg-brandRed'}`}>
                    {value ? 'active' : 'inactive'}
                </span>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )
        }

    ];


    const handleEdit = (row) => {
        setSelectedPlanDataCategory(row)
        setShowAddAndEditPlanCategory(true)
        setIsEdit(true)
    }

    const handleSubmit = async (value) => {
        const { id, ...data } = value
        let res
        if (isEdit) {
            res = await updatePlanCategory(id, data)
        } else {
            res = await createNewPlanCategory(data)
        }
        if (res?.success) {
            toast(`${isEdit ? 'updated' : 'Created'} Plan Category`)
            setIsEdit(false)
            setShowAddAndEditPlanCategory(false)
            fetchAllPlansCategory()
        }

    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedPlanDataCategory('')
        setShowAddAndEditPlanCategory(false)
    }

    return (
        <div className='flex flex-col h-screen'>


            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Plan Category"}
                        subHeading={"Manage your events effectively"}
                        btnText={"Create Plan Category"}
                        handleBtn={() => setShowAddAndEditPlanCategory(true)} />
                </div>

                {/* Table */}
                {showAddAndEditPlanCategory ?
                    <div className="w-[98%] flex items-center justify-center">
                        <Suspense fallback={<div>loading...</div>} >
                            <CreateAndEditPlanCategory data={selectedPlanDataCategory} handleCancel={handleCancel} handleSubmit={(value) => handleSubmit(value)} />
                        </Suspense>
                    </div>
                    :
                    <div className="w-full p-8">
                        {planCategory &&
                            <TableWithoutPagination columns={columns} data={planCategory} />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Index