
import React, { useEffect, useState } from 'react';
import { getFastSmsWalletBalance } from 'apis/home';


const Index = () => {
    const [balance, setBalance] = useState()
    const fetchFastSms = async () => {
        const res = await getFastSmsWalletBalance()
        if (res?.data) setBalance(res?.data?.Balance)
    }

    useEffect(() => {
        fetchFastSms()
    }, [])

    return (
        <div className="container mx-auto">
            <StatsCard
                title={"Balance"}
                value={`RS ${balance}`}
            />
        </div>
    );
};

export default Index;


const StatsCard = ({ title, value }) => {
    return (
        <div className="p-4 bg-[#e3f5ff]  rounded-lg  shadow-xl w-[210px] h-[112px]">
            <h2 className="text-lg font-semibold">{title}</h2>
            <div className="flex space-x-4 overflow-x-auto hide-scrollbar">
                <div className={` p-4 rounded-md w-[210px] h-[112px]`}>
                    <div className="">{value}</div>
                </div>
            </div>
        </div>
    )
}