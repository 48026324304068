import { uploadImage } from 'apis/blog'; // Import image handling functions
import AdvancedImageCropping from 'components/NEW/image/imageCropping';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { toast } from 'react-toastify';

// Define a custom blot for YouTube videos
const Embed = Quill.import('blots/block/embed');

class VideoBlot extends Embed {
    static create(value) {
        const node = super.create();
        node.setAttribute('src', value);
        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', true);
        node.setAttribute('width', '100%');
        node.setAttribute('height', '315');
        return node;
    }

    static value(node) {
        return node.getAttribute('src');
    }
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';
VideoBlot.className = 'ql-video';

Quill.register(VideoBlot);

const TextEditor = ({ setData, data }) => {
    const quillRef = useRef(null); // Create a ref for the ReactQuill component
    const imageUrls = useRef(new Set()); // Track image URLs

    useEffect(() => {
        // Ensure Arial is properly registered in Quill
        const Font = ReactQuill.Quill.import('formats/font');
        Font.whitelist = ['arial']; // Add other common fonts as well for variety
        ReactQuill.Quill.register(Font, true);

        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            const toolbar = editor.getModule('toolbar');
            toolbar.addHandler('image', handleImageUpload);
            toolbar.addHandler('link', handleLink);
        }
    }, []);

    const modules = {
        toolbar: {
            container: [
                [{ 'font': ['arial'] }, { 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }], // Use built-in color and background color options
                [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image'],
                ['clean']
            ]
        }
    };

    const formats = [
        'font', 'header',
        'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'align',
        'list', 'bullet',
        'link', 'image', 'video', // Add 'video' to formats
    ];

    const [advanceCropping, setAdvanceCropping] = useState(false);
    const [imgUrl, setImgUrl] = useState(null);

    const handleCropComplete = async (croppedFile) => {
        setAdvanceCropping(!advanceCropping);
        try {
            const formData = new FormData();
            formData.append('file', croppedFile);

            const toastId = toast.loading('Uploading cropped image, please wait...');
            const res = await uploadImage(formData);
            toast.dismiss(toastId);
            toast.success('Image uploaded successfully');

            if (res?.data) {
                const editor = quillRef.current.getEditor();
                const range = editor.getSelection();
                if (range) {
                    editor.insertEmbed(range.index, 'image', res?.data);
                    imageUrls.current.add(res?.data);
                }
            }
        } catch (error) {
            toast.error('Failed to upload image');
        }
    };

    // Custom image handler
    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImgUrl(reader.result);
                    setAdvanceCropping(true); // Trigger the cropping modal
                };
                reader.readAsDataURL(file);
            }
        };
    };

    // Custom link handler to embed YouTube videos
    const handleLink = () => {
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection(true);
        const value = prompt('Enter the URL');

        if (value && isValidUrl(value)) {
            if (isYouTubeUrl(value)) {
                const videoUrl = convertYouTubeToEmbedUrl(value);
                editor.insertEmbed(range.index, 'video', videoUrl);
            } else {
                editor.format('link', value);
            }
        }
    };

    // Function to validate if a URL is a YouTube link
    const isYouTubeUrl = (url) => {
        return /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/.test(url);
    };

    // Function to convert YouTube link to embeddable format
    const convertYouTubeToEmbedUrl = (url) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i;
        const match = url.match(regex);
        return match ? `https://www.youtube.com/embed/${match[1]}` : url;
    };

    // Function to validate general URLs
    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    return (
        <div>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={data}
                onChange={setData}
                modules={modules}
                formats={formats}
                placeholder="Compose your message..."
            />

            {advanceCropping && (
                <div className="h-[30vh] w-[50%] mb-[10%] flex items-center justify-center">
                    <AdvancedImageCropping
                        image={imgUrl}
                        setPopup={() => setAdvanceCropping(!advanceCropping)}
                        onCropComplete={handleCropComplete}
                        ratio={11 / 6}
                    />
                </div>
            )}
        </div>
    );
};

export default TextEditor;
