
import { MainNavbar, MainSidebar } from "components";
import React, { useEffect, useState } from 'react';

import { fetchBlogById } from 'apis/blog';
import { BLOG } from 'common/routes';
import Advertisement from './components/advertisement';
import FeaturedImage from './components/featuredImage';
import Final from './components/final';
import MetaForm from './components/meteForm';
import TextEditor from './components/textEditor';
import TitleForm from './components/title';

// import TextEditor from 'components/NEW/blog/createBlog/textEditor';
import { Link, useParams } from 'react-router-dom';
import { decodeFromBase64 } from 'utils/encodeAndDecode';

const Index = () => {
    const { id } = useParams();
    const params = new URLSearchParams(window.location.search);

    const [currentStep, setCurrentStep] = useState(1);
    const [saveData, setSaveData] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [blogId, setBlogId] = useState()
    const [blogData, setBlogData] = useState()


    // Function to render components based on currentStep
    const renderComponent = () => {

        switch (currentStep) {
            case 1:
                return <TitleForm saveData={saveData} onSaveSuccess={(value) => handleSuccess(value)} onSaveFailed={() => setSaveData(false)} isEdit={isEdit} blogData={blogData} />;
            case 2:
                return <MetaForm saveData={saveData} onSaveSuccess={(value) => handleSuccess(value)} onSaveFailed={() => setSaveData(false)} id={blogId} blogData={blogData} />;
            case 3:
                return <FeaturedImage saveData={saveData} onSaveSuccess={(value) => handleSuccess(value)} onSaveFailed={() => setSaveData(false)} id={blogId} blogData={blogData} />;
            case 4:
                return <Advertisement saveData={saveData} onSaveSuccess={(value) => handleSuccess(value)} onSaveFailed={() => setSaveData(false)} id={blogId} blogData={blogData} />;
            case 5:
                return <TextEditor saveData={saveData} onSaveSuccess={(value) => handleSuccess(value)} onSaveFailed={() => setSaveData(false)} id={blogId} blogData={blogData} />;
            case 6:
                return <Final saveData={saveData} onSaveSuccess={(value) => handleSuccess(value)} onSaveFailed={() => setSaveData(false)} id={blogId} isEdit={isEdit} blogData={blogData} />;
            default:
                return <MetaForm />;
        }
    };

    const handleSuccess = (value) => {
        setBlogId(value)
        setSaveData(false);
        setCurrentStep(currentStep + 1);
    };

    const getBlogById = async () => {
        const decodedId = decodeFromBase64(id)
        const res = await fetchBlogById(decodedId)
        setBlogData(res?.data)
        setBlogId(decodedId)
        if (params.get('edit') === 'true') {
            setCurrentStep(1)
            setIsEdit(true)
        } else {
            setCurrentStep(res?.data?.current_index + 1)
        }
    }

    useEffect(() => {
        if (id) getBlogById()
    }, [id])

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10 flex items-center justify-between">
                        {/* left side */}
                        <div className="max-w-[30%]">
                            <h1 className='text-2xl font-semibold'>Welcome to New blog</h1>
                            <p className='mt-2 text-sm text-gray-500'>Please fill the form below to receive a quote for your project. Feel free to add as much detail as needed.</p>
                        </div>
                        {/* right side btns */}
                        <div className="max-w-[30%] flex items-center gap-4">
                            <Link to={BLOG.BLOG} className='p-3 px-6 bg-white border-2 rounded-2xl border-brandRed'>Cancel</Link>
                            <button
                                onClick={() => setSaveData(true)}
                                className='p-3 px-6 text-white rounded-2xl bg-brandRed'>save</button>
                        </div>
                    </div>

                    <div className="w-[90%] mx-auto">
                        {renderComponent()}
                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;