/* eslint-disable unused-imports/no-unused-imports */
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SideBarSection from './sideBarSection';
import { SIDEBAR } from 'common/routes';

import {
    // Active
    homeActive, userActiveIcon, eventActiveIcon, planActiveIcon, coupounActiveIcon, notificationActiveIcon, verificationActiveIcon, mobileActiveIcon, blogActiveIcon, offerActiveIcon, hotspotActiveIcon, afinityScoreActiveIcon, religionAndCastActiveIcon,
    // Inactive
    homeInactive, userInactiveIcon, eventInactiveIcon, planInactiveIcon, coupounInactiveIcon, notificationInactiveIcon, verificationInactiveIcon, mobileInactiveIcon, blogInactiveIcon, offerInactiveIcon, hotspotInactiveIcon, afinityScoreInactiveIcon, religionAndCastInactiveIcon,
    // Other
    downArrowIcon, affiliateReferralIcon, affiliateReferralInactiveIcon
} from "assets/mainSidebar/icons";

const sidebarItems = {
    home: [
        { name: 'Overview', location: SIDEBAR.HOME_OVERVIEW },
        { name: 'External Services', location: SIDEBAR.EXTERNAL_SERVICES }
    ],
    user: [
        { name: 'View User', location: SIDEBAR.VIEW_USERS },
        { name: 'User Activity', location: SIDEBAR.VIEW_ACTIVITY_BASED_USERS },
        { name: 'Deleted Users', location: SIDEBAR.DELETED_USERS }
    ],
    event: [
        { name: 'Events', location: SIDEBAR.EVENTS },
        { name: 'Event Connection', location: SIDEBAR.EVENTS_CONNECTIONS },
        { name: 'Event Comments', location: SIDEBAR.EVENTS_COMMENTS },
        { name: 'Event Category', location: SIDEBAR.EVENTS_CATEGORY },
        { name: 'Event Location', location: SIDEBAR.EVENTS_LOCATION },
        { name: 'Event Tags', location: SIDEBAR.EVENTS_TAG },
        { name: 'Event Host', location: SIDEBAR.EVENTS_HOST },
    ],
    plan: [
        { name: 'Plan', location: SIDEBAR.PLANS },
        { name: 'Plan Category', location: SIDEBAR.PLAN_CATEGORY },
        { name: 'Plan Limitation', location: SIDEBAR.PLAN_LIMITATION },
        { name: 'Plan Coins', location: SIDEBAR.PLAN_COIN },
    ],
    coupon: [{ name: 'Coupons', location: SIDEBAR.COUPON }],
    notification: [
        { name: 'Notification', location: SIDEBAR.NOTIFICATION },
        { name: 'History', location: SIDEBAR.NOTIFICATION_HISTORY },
    ],
    verification: [{ name: 'Verification', location: SIDEBAR.VERIFICATION }],
    affiliateReferral: [{ name: 'Affiliate Coupons', location: SIDEBAR.AFFILIATE_COUPONS }],
    appUpdate: [{ name: 'AppUpdate', location: SIDEBAR.APP_UPDATE }],
    blog: [{ name: 'Blog', location: SIDEBAR.BLOG }],
    offer: [{ name: 'Offer', location: SIDEBAR.OFFER }],
    hotspot: [{ name: 'Hotspot', location: SIDEBAR.HOTSPOT }],
    affinityScore: [{ name: 'Affinity Score', location: SIDEBAR.AFFINITY_SCORE }],
    religionAndCast: [
        { name: 'Religion', location: SIDEBAR.RELIGION },
        { name: 'Cast', location: SIDEBAR.CAST }
    ]
};

function Index({ isCollapsed, onToggle }) {
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState('');


    // Memoize all items to avoid recalculation on every render
    const allItems = useMemo(() => Object.values(sidebarItems).flat(), []);

    useEffect(() => {
        const currentSidebarItem = allItems?.find(item => item?.location === location?.pathname);
        setCurrentTab(currentSidebarItem?.name || '');
    }, [location, allItems]);

    // Check if an item is active
    const isActive = (itemName) => itemName === currentTab;

    // Check if the parent section should be active
    const isParentActive = (items) => items?.some(item => isActive(item?.name));

    const sidebarMenus = useMemo(
        () => [
            { title: "Home", icon: [homeActive, homeInactive], items: sidebarItems.home },
            { title: "User Management", icon: [userActiveIcon, userInactiveIcon], items: sidebarItems.user },
            { title: "Event Management", icon: [eventActiveIcon, eventInactiveIcon], items: sidebarItems.event },
            { title: "Plan Management", icon: [planActiveIcon, planInactiveIcon], items: sidebarItems.plan },
            { title: "Coupon Management", icon: [coupounActiveIcon, coupounInactiveIcon], items: sidebarItems.coupon },
            { title: "Notification Management", icon: [notificationActiveIcon, notificationInactiveIcon], items: sidebarItems.notification },
            { title: "Verification Management", icon: [verificationActiveIcon, verificationInactiveIcon], items: sidebarItems.verification },
            { title: "Affiliate Coupons", icon: [affiliateReferralIcon, affiliateReferralInactiveIcon], items: sidebarItems.affiliateReferral },
            { title: "App Update", icon: [mobileActiveIcon, mobileInactiveIcon], items: sidebarItems.appUpdate },
            { title: "Blog", icon: [blogActiveIcon, blogInactiveIcon], items: sidebarItems.blog },
            { title: "Offer", icon: [offerActiveIcon, offerInactiveIcon], items: sidebarItems.offer },
            { title: "Hotspot", icon: [hotspotActiveIcon, hotspotInactiveIcon], items: sidebarItems.hotspot },
            { title: "Affinity Score", icon: [afinityScoreActiveIcon, afinityScoreInactiveIcon], items: sidebarItems.affinityScore },
            { title: "Religion & Cast", icon: [religionAndCastActiveIcon, religionAndCastInactiveIcon], items: sidebarItems.religionAndCast },
        ],
        [currentTab]
    );


    return (
        <div className={`h-full text-black bg-white transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-64'}`}>
            <div className='flex items-center justify-around my-5 text-base font-bold text-gray-500'>
                {/* Hide Main Menu text when collapsed */}
                {!isCollapsed && <h1>Main Menu</h1>}
                <img
                    src={downArrowIcon}
                    alt="Toggle"
                    className={`cursor-pointer transition-transform  duration-300 ${isCollapsed ? '-rotate-90' : 'rotate-90'}`}
                    onClick={onToggle}
                />
            </div>

            {sidebarMenus.map((menu, index) => (
                <SideBarSection
                    key={index}
                    title={menu.title}
                    icon={menu.icon}
                    items={menu.items}
                    isActive={isActive}
                    isParentActive={isParentActive(menu.items)}
                    isCollapsed={isCollapsed} // Pass the collapsed state to SidebarSection
                />
            ))}
        </div>
    );
}

export default Index;
