import { fetchRazorpayWebhookStatus, updateRazorpayWebhookStatus } from 'apis/home'
import { ToggleBtn } from 'components'
import React, { useEffect, useState } from 'react'

function Index() {
    const [isActive, setIsActive] = useState(false)

    const handleOnChange = async () => {
        const res = await updateRazorpayWebhookStatus()
        if (res?.success) setIsActive(res?.data?.active)
    }

    const getWebhookStatus = async () => {
        const res = await fetchRazorpayWebhookStatus()
        if (res?.success) setIsActive(res?.data)
    }

    useEffect(() => {
        getWebhookStatus()
    }, [])

    return (
        <div>
            <ToggleBtn
                checked={isActive}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default Index