import React, { useState } from 'react';
import ReactFroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/colors.min.js';

import './style.css';
import { uploadImage } from 'apis/blog';
import { toast } from 'react-toastify';

const FroalaEditor = ({ handleSaveData, data }) => {

    const [editorContent, setEditorContent] = useState(data || '<p>content</p>');

    // Function to upload the image to the server
    const handleImageUpload = async (file, success) => {
        const formData = new FormData();
        formData.append('file', file);
        const res = await uploadImage(formData);
        const toastId = toast.loading('image uploading')
        if (res?.success) {
            success(res.data)
            toast.success('Success')
        }
        toast.dismiss(toastId)

    };

    const handleModelChange = (model) => {
        setEditorContent(model);
    };

    const handleSave = () => {
        handleSaveData(editorContent)
    };


    return (
        <div className="froala-editor-container">
            <ReactFroalaEditor
                tag="textarea"
                model={editorContent}
                onModelChange={handleModelChange}
                config={{
                    placeholderText: 'Start typing...',
                    toolbarButtons: [
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'fontFamily',
                        'fontSize',
                        'paragraphFormat',
                        'align',
                        'formatOL',
                        'formatUL',
                        'insertLink',
                        'insertImage',
                        'textColor',
                        'backgroundColor',
                        'insertHR',
                        '|',
                        'undo',
                        'redo',
                    ],
                    heightMin: 300,
                    pluginsEnabled: [
                        'align',
                        'lists',
                        'fontSize',
                        'fontFamily',
                        'paragraphFormat',
                        'link',
                        'image',
                        'textColor',
                        'backgroundColor',
                        'colors',
                        'insertHR'
                    ],
                    colorsText: [
                        '#000000', '#333333', '#666666', '#999999', '#CCCCCC', '#FFFFFF',
                        '#FF0000', '#FF9900', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF', '#9900FF', '#FF00FF',
                        '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                        '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                        '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6FA8DC', '#8E7CC3', '#C27BA0',
                        '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#674EA7', '#A64D79',
                        '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#0B5394', '#351C75', '#741B47',
                        '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#073763', '#20124D', '#4C1130',
                    ],
                    fontFamily: {
                        'Arial, Helvetica, sans-serif': 'Arial',
                        'Georgia, serif': 'Georgia',
                        'Impact, Charcoal, sans-serif': 'Impact',
                        'Tahoma, Geneva, sans-serif': 'Tahoma',
                        "'Times New Roman', Times, serif": 'Times New Roman',
                        'Verdana, Geneva, sans-serif': 'Verdana',
                        "'Courier New', Courier, monospace": 'Courier New',
                    },
                    fontSize: ['12', '14', '16', '18', '20', '24', '30', '36', '48', '60', '72', '96'],
                    paragraphFormat: {
                        N: 'Normal',
                        H1: 'Heading 1',
                        H2: 'Heading 2',
                        H3: 'Heading 3',
                        H4: 'Heading 4',
                        H5: 'Heading 5',
                        H6: 'Heading 6',
                    },
                    imageUpload: true, // Enable image upload
                    imageUploadURL: '', // Required if using Froala's default upload handler
                    imageUploadMethod: 'POST',
                    imageMaxSize: 5 * 1024 * 1024, // 5MB max size
                    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
                    imageUploadToS3: false, // Use your custom upload
                    imageUploadParams: {}, // Empty since custom function is used
                    events: {
                        'image.beforeUpload': function (images) {
                            if (images.length) {
                                handleImageUpload(
                                    images[0], // Pass the first image file
                                    (url) => {
                                        this.image.insert(url, true, null, this.image.get(), null);
                                    }
                                );
                            }
                            return false; // Prevent Froala from uploading the image
                        },
                    },
                }}
            />
            <div className="flex items-center justify-center w-full">
                <button className='p-3 px-10 mt-5 text-white bg-green-500 rounded-lg' onClick={handleSave}>Save Content</button>
            </div>
        </div>
    );
};

export default FroalaEditor;
