import { USERS } from "common/routes.js"
import { ManagementSecMainHeader, Popup, Table } from "components"
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getAllUsersBasedOnActivity } from 'apis/users/index.js'
import { formatDateToDDMMYYInNumber } from 'shared/dates.js'
import { encodeToBase64 } from 'utils/encodeAndDecode'

import verification from 'assets/icons/verified.png'
import { UserActionBasedFilter } from "components/NEW/filters"


function Index() {

    const navigate = useNavigate()
    const [userData, setUserData] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [accountStatusPopup, setAccountStatusPopup] = useState(false)

    const columns = [

        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'JoinedAt', accessor: (row) => formatDateToDDMMYYInNumber(row.created_at) },
        { Header: 'Name', accessor: 'name' },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: ({ row }) => (
                <div className='flex items-center'>
                    <p>{row.original.email}</p>
                    {row.original.verification?.status === 'verified' && (
                        <img src={verification} alt="Verified" className='ms-2 w-[20px] h-[20px]' />
                    )}
                </div>
            )
        },
        {
            Header: 'Photo',
            accessor: 'photo',
            Cell: ({ value }) => (
                <img src={value} alt="No Photo" className='w-[66px] h-[70px] cursor-pointer' />
            )
        },
        { Header: 'Phone', accessor: 'phone' },
        { Header: 'Category', accessor: 'relationship_status' },
        { Header: 'Gender', accessor: 'sex' },
        { Header: 'Last activity', accessor: 'latest_activity' },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 px-3 mr-1 text-white bg-brandRed rounded-xl'>Edit</button>
                </div>
            )
        },
    ];

    const handleEdit = (row) => {
        const id = encodeToBase64(row.original.userId)
        const url = USERS.EDIT_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleFilterData = (value) => {
        const savedFilters = JSON.parse(sessionStorage.getItem('userActivityFilter'));
        fetchAllUsers(savedFilters?.page || 1, value)
    }

    const fetchAllUsers = async (page = 1, value, limit = rowsPerPage) => {
        setUserData(null)
        const savedFilters = JSON.parse(sessionStorage.getItem('userActivityFilter'));
        let payload
        if (value) {

            payload = {
                ...value,
                page: page,
                limit: limit
            };
        } else if (savedFilters) {
            payload = {
                ...savedFilters,
                page: page,
                limit: limit
            };
        } else {
            payload = {
                page: page,
                limit: limit
            };
        }
        const res = await getAllUsersBasedOnActivity(payload)
        setUserData(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
        sessionStorage.setItem('userActivityFilter', JSON.stringify(payload));
    }

    useEffect(() => {
        const savedFilters = JSON.parse(sessionStorage.getItem('userActivityFilter'));
        fetchAllUsers(savedFilters?.page || 1, savedFilters || null)
    }, [])

    const handleBtn = () => {
        navigate(USERS.CREATE_USERS)
    }


    return (
        <div className='flex flex-col h-screen'>
            {accountStatusPopup &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <Popup text={'Are you sure ?'} onClose={() => setAccountStatusPopup(false)} onConfirm={() => handleUserAccountStatus(accountStatusPopup)} />
                </div>
            }

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Create User"}
                        subHeading={"user Management"}
                        btnText={"Add User"}
                        handleBtn={handleBtn}
                        handleFilterData={(value) => handleFilterData(value)}
                        FilterComponent={UserActionBasedFilter}
                    />
                </div>
                {/* Table */}
                <div className="p-8">
                    <div className="flex items-center">

                        <div className="flex items-center">
                            <label htmlFor="rowsPerPage" className="block text-sm font-medium text-gray-700">
                                Rows per page:
                            </label>
                            <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                value={rowsPerPage}
                                onChange={(e) => {
                                    setRowsPerPage(e.target.value)
                                    fetchAllUsers(pageNation.currentPage, null, e.target.value)
                                }}
                                className="block p-2 px-6 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                        {userData &&
                            <div className="flex items-center ms-2">
                                <h1>Total Filtered Users</h1>
                                <h1 className='font-semibold ms-2'>: {userData.length > 0 ? userData.length : 0}</h1>
                            </div>
                        }
                    </div>

                    {userData ?
                        <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchAllUsers(value)} rowsPerPage={rowsPerPage} />
                        :
                        <div className="flex items-center justify-center w-full text-2xl text-black">Loading the Data Please Wait</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index