/* eslint-disable unused-imports/no-unused-vars */
import { sendNotifications } from "apis/notification"
import { MainNavbar, MainSidebar } from "components"
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from "react-toastify"
import { decodeFromBase64 } from 'utils/encodeAndDecode'


const UserBasicDetails = lazy(() => import('./components/basicDetails'))
const UserPrivacySettings = lazy(() => import('./components/privacySettings'))
const UserConnections = lazy(() => import('./components/connections'))
const UserEvents = lazy(() => import('./components/events'))
const UserWallet = lazy(() => import('./components/wallet'))
const UserReffrel = lazy(() => import('./components/reffrel'))
const UserHistory = lazy(() => import('./components/history'))
const CreateNotification = lazy(() => import("components/NEW/notificationManagement/createNotification"))


function Index() {
    const { id } = useParams();
    const decodedId = decodeFromBase64(id)

    const [currentTab, setCurrentTab] = useState('Basic Info')
    const header = ['Basic Info', 'Wallet', 'Connections', 'Events', 'User Privacy Settings', 'Referel', 'History', 'Notification']

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentTab]);

    const handleSubmitNotification = async (value, boolean) => {
        const data = {
            ...value,
            type: "promotional",
            users: [decodedId],
            all_users: false
        }
        const res = await sendNotifications(data)
        if (res.success) toast.success("Notification sent successfully")
    }

    function getTabComponent(tabName) {
        switch (tabName) {
            case 'Basic Info':
                return <UserBasicDetails decodedId={decodedId} edit={true} />;
            case 'User Privacy Settings':
                return <UserPrivacySettings decodedId={decodedId} edit={true} />;
            case 'Connections':
                return <UserConnections decodedId={decodedId} edit={true} />;
            case 'Events':
                return <UserEvents decodedId={decodedId} edit={true} />;
            case 'Wallet':
                return <UserWallet decodedId={decodedId} edit={true} />;
            case 'Referel':
                return <UserReffrel decodedId={decodedId} edit={true} />;
            case 'History':
                return <UserHistory decodedId={decodedId} edit={true} />;
            case 'Notification':
                return <CreateNotification allUser={false} cancel={false} handleSubmit={(value, boolean) => handleSubmitNotification(value, boolean)} />;
            default:
                return <div>Select a tab</div>;
        }
    }


    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-full w-10/12">
                    {/* Header */}
                    <div className='flex overflow-x-auto m-10 mb-5 h-[60px] bg-white justify-around' style={{ scrollbarWidth: 'none' }}>
                        {header.map((item, index) => (
                            <h1
                                className={`p-3 w-full cursor-pointer ${currentTab === item ? 'text-white bg-brandRed rounded-t-lg' : 'text-black'}`}
                                key={index}
                                onClick={() => setCurrentTab(item)}
                            >
                                {item}
                            </h1>
                        ))}
                    </div>
                    {/* Content Section */}
                    <div className="p-8 pt-0">
                        <Suspense fallback={<div>Loading...</div>}>
                            {getTabComponent(currentTab)}
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index