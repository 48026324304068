import DropDown from "components/NEW/ui/dropDown";
import InputField from "components/NEW/ui/inputField";
import React, { useEffect, useState } from "react";
import { genderOptions, user_activity_actions } from "shared/constants";
import { capitalize } from "shared/textConverter";

function Index({ handleFilterData, handleShowFilter }) {
    const [data, setData] = useState({
        actionNames: [],
        from_date: null,
        to_date: null,
        gender: 'unisex',
    });
    const [searchText, setSearchText] = useState("")


    const handleStateUpdate = (key, value) => {
        setData(prev => ({ ...prev, [key]: value }));
    };

    const toggleUserActivity = (action) => {
        setData((prev) => {
            const isSelected = prev?.actionNames?.includes(action);

            // Add or remove the action from the array
            const updatedActions = isSelected
                ? prev.actionNames.filter((item) => item !== action)
                : [...prev.actionNames, action];

            return { ...prev, actionNames: updatedActions };
        });
    };

    useEffect(() => {
        const savedFilters = sessionStorage.getItem('userActivityFilter');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            setData((prevData) => ({
                ...prevData,
                ...parsedFilters,
            }));
        }
    }, []);

    const filteredUserActivities = user_activity_actions.filter((item) =>
        item.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="w-full h-full max-h-screen p-3 overflow-y-auto border border-gray-300">
                <div className="relative flex flex-col">
                    <div className="mb-5 text-lg font-semibold">Activity based Filter</div>

                    <div className="my-2">
                        <label>From Date</label>
                        <InputField
                            height={'h-[40px]'}
                            onChange={(value) => handleStateUpdate('from_date', value)}
                            value={data.from_date}
                            type={'date'}
                        />
                    </div>
                    <div className="my-2">
                        <label>To Date</label>
                        <InputField
                            height={'h-[40px]'}
                            onChange={(value) => handleStateUpdate('to_date', value)}
                            value={data.to_date}
                            type={'date'}
                        />
                    </div>

                    <div className="my-2">
                        <label>Gender</label>
                        <DropDown
                            height={'h-[35px]'}
                            options={genderOptions}
                            handleOnChange={(value) => handleStateUpdate('gender', value)}
                            selectedValue={data.gender}
                        />
                    </div>

                    <div className="mt-5 mb-2 text-xl font-semibold">Activity</div>
                    <div className="my-4">
                        <InputField
                            height={"h-[40px]"}
                            placeholder="Search activities..."
                            onChange={(value) => setSearchText(value)}
                            value={searchText}
                        />
                    </div>

                    {filteredUserActivities?.map((item, index) => {
                        const isSelected = data?.actionNames?.includes(item);
                        return (
                            <div
                                key={index}
                                onClick={() => toggleUserActivity(item)}
                                className={`flex cursor-pointer p-2 my-1 border ${isSelected ? 'bg-gray-300' : ''}`}
                            >
                                {capitalize(item)}
                            </div>
                        );
                    })}
                </div>

                <div className="flex justify-center gap-4 p-4 mt-auto">
                    <button
                        onClick={() => handleFilterData(data)}
                        className="p-4 font-semibold text-white transition duration-300 bg-green-500 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                        Submit
                    </button>
                    <button
                        onClick={handleShowFilter}
                        className="p-4 font-semibold text-white transition duration-300 bg-red-500 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Index;
