import React, { Suspense, lazy, useEffect, useState } from 'react'
import { addReligion, editReligion, fetchReligions } from 'apis/religionAndCaste'
import { ManagementSecMainHeader, TableWithoutPagination } from 'components'
import { toast } from 'react-toastify'


const CreateAndEditReligionAndCast = lazy(() => import('../components/createAndEditPopup'))


function Index() {


    const [religion, setReligion] = useState()
    const [showAddAndEditReligion, setShowAddAndEditReligion] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedReligion, setSelectedReligion] = useState('')

    const getAllReligion = async () => {
        const res = await fetchReligions()
        setReligion(res?.data)
    }

    useEffect(() => {
        getAllReligion()
    }, [])

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Religion Name', accessor: 'name', maxWidth: 100 },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <span className={`text-white p-2 rounded-3xl ${value ? 'bg-green-500' : 'bg-brandRed'}`}>
                    {value ? 'active' : 'inactive'}
                </span>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )
        }

    ];


    const handleEdit = (row) => {
        setSelectedReligion(row)
        setShowAddAndEditReligion(true)
        setIsEdit(true)
    }

    const handleSubmit = async (value) => {
        let res
        if (isEdit) {
            res = await editReligion(value, selectedReligion?.id)
        } else {
            res = await addReligion(value)
        }
        if (res?.success) {
            toast(`${isEdit ? 'updated' : 'Created'} Religion`)
            setShowAddAndEditReligion(false)
            setIsEdit(false)
            setSelectedReligion('')
            getAllReligion()
        }
    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedReligion('')
        setShowAddAndEditReligion(false)
    }

    return (

        <div className="flex flex-col h-full">
            {/* Popup for Adding and Editing Religion */}
            {showAddAndEditReligion && (
                <div className="absolute inset-0 z-50 flex items-center justify-center h-full">
                    <Suspense fallback={<div>loading...</div>}>
                        <div className="fixed p-4 bg-white rounded-md shadow-lg w-fit">
                            <CreateAndEditReligionAndCast
                                data={selectedReligion}
                                handleCancel={handleCancel}
                                handleSubmit={(value) => handleSubmit(value)}
                                isReligion={true}
                                isEdit={isEdit}
                            />
                        </div>
                    </Suspense>
                </div>
            )}

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Religion"}
                        btnText={"Add Religion"}
                        handleBtn={() => setShowAddAndEditReligion(true)}
                    />
                </div>

                {/* Table */}
                <div className="w-full p-8">
                    {religion && <TableWithoutPagination columns={columns} data={religion} />}
                </div>
            </div>
        </div>

    )
}

export default Index