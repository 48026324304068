import { UpdateBlog } from 'apis/blog'
import { Steps, FroalaTextEditer } from 'components'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'


function Index({ saveData, onSaveSuccess, onSaveFailed, id, blogData }) {
    const handleSaveData = async (data) => {
        const apiData = {
            id,
            current_index: 5,
            data,
        }
        const toastId = toast.loading('Creating blog')
        const res = await UpdateBlog(apiData)
        toast.dismiss(toastId)
        if (res?.success) {
            onSaveSuccess(res?.data)
        } else {
            onSaveFailed()
        }
    }

    useEffect(() => {
        if (saveData) handleSaveData()
    }, [saveData,])
    return (
        <div className="w-full p-6 mx-auto bg-white rounded-lg shadow-lg">
            {/* Progress Bar */}
            <div className="flex items-center mb-8">
                <Steps number={1} isActive={true} lineActive={true} />
                <Steps number={2} isActive={true} lineActive={true} />
                <Steps number={3} isActive={true} lineActive={true} />
                <Steps number={4} isActive={true} lineActive={true} />
                <Steps number={5} isActive={true} />
                <Steps number={6} displayLine={false} />
            </div>

            <FroalaTextEditer handleSaveData={(data) => handleSaveData(data)} data={blogData?.blog_content || false} />
        </div>
    )
}

export default Index
