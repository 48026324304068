import { ManagementSecMainHeader, Table } from "components";
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';

import { getAllUserBasedOnStatus, updateUserBasedOnStatus } from 'apis/verification/index.js';
import { USERS } from 'common/routes';
import DropDown from 'components/NEW/ui/dropDown';
import { rejectedMessage, verifiedMessage, waitingListMessage } from 'constant/verificationMessage';
import { toast } from 'react-toastify';
import { verificationStatus } from 'shared/constants';
import { formatDateToDDMMYY } from 'shared/dates.js';
import { capitalize } from 'shared/textConverter.js';
import { encodeToBase64 } from 'utils/encodeAndDecode';

const NameVerification = lazy(() => import('./components/name'))
const IdVerification = lazy(() => import('./components/id'))
const PhotoVerification = lazy(() => import('./components/photo'))
const StatusVerification = lazy(() => import('./components/status'))
const ConfirmationPopup = lazy(() => import('components/NEW/popups/confirm'))


function Index() {

    const [userData, setUserData] = useState([])
    const [status, setStatus] = useState('requested')
    const [selectedGender, setSelectedGender] = useState('')
    const [search, setSearch] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [isConfirmationPopup, setIsConfirmationPopup] = useState({
        visibility: false,
        id: null,
        status: null,
    })
    const [selectedUser, setSelectedUser] = useState()
    const [isVisible, setIsVisible] = useState({
        table: true,
        NAME: false,
        ID: false,
        PHOTO: false,
        STATUS: false,
    })
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const headerContent = ['NAME', 'ID', 'PHOTO', 'STATUS']

    const columns = [

        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleViewUserProfile(row)} className='cursor-pointer hover:text-blue-500 hover:underline'>{row?.original?.name}</button>
                </div>
            )
        },
        {
            Header: 'profile completion', accessor: 'profile_completion',
        },
        { Header: 'Id Type', accessor: (row) => capitalize(row.id_type) },
        {
            Header: 'User Photo',
            accessor: 'user_photo',
            Cell: ({ value }) => (
                <img src={value} alt="userPhoto" className='w-[66px] h-[70px] cursor-pointer' onClick={() => handleOnClickImage(value)} />
            )
        },
        {
            Header: 'Id Front',
            accessor: 'id_front_side',
            Cell: ({ value }) => (
                <img src={value} alt="userPhoto" className='w-[66px] h-[70px] cursor-pointer' onClick={() => handleOnClickImage(value)} />
            )
        },
        {
            Header: 'Id Back',
            accessor: 'id_back_side',
            Cell: ({ value }) => (
                <img src={value} alt="userPhoto" className='w-[66px] h-[70px] cursor-pointer' onClick={() => handleOnClickImage(value)} />
            )
        },
        { Header: 'Date', accessor: (row) => formatDateToDDMMYY(row.createdAt) },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleView(row)} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>View</button>
                </div>
            )
        },
        {
            Header: 'Actions',
            accessor: 'status',
            Cell: ({ row }) => (
                <div>
                    <DropDown
                        selectedValue={row?.original?.status}
                        options={verificationStatus}
                        handleOnChange={(value) => setIsConfirmationPopup({ id: row?.original?.userId, status: value, visibility: true })}
                    />
                </div>
            )
        }
    ];

    const handleUpdateVerification = async () => {
        const value = isConfirmationPopup.status
        if (value == 'requested') {
            toast.error("you can't update the status as request")
        } else {
            const toastId = toast.loading('updating user request status')
            const data = {
                status: isConfirmationPopup.status,
                message: value === 'verified' ? verifiedMessage.title : (value === 'rejected' ? rejectedMessage.title : waitingListMessage.title),
                user_message: value === 'verified' ? verifiedMessage.message : (value === 'rejected' ? rejectedMessage.message : waitingListMessage.message),
            }
            const res = await updateUserBasedOnStatus(isConfirmationPopup.id, data)
            if (res?.success) {
                toast.dismiss(toastId)
                toast.success('Updated Successfully')
                fetchAllUserBasedOnStatus(pageNation.currentPage, rowsPerPage, status)
            }
            setIsConfirmationPopup({ visibility: false })
            toast.dismiss(toastId)
        }
    }

    const handleOnClickImage = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const handleViewUserProfile = (row) => {
        const id = encodeToBase64(row.original.userId)
        const url = USERS.EDIT_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleView = (row) => {
        setSelectedUser(row.original)
        handleChangeVisibility('NAME')
    }

    const handleChangeVisibility = useCallback((key) => {
        setIsVisible(prev => ({
            ...Object.keys(prev).reduce((acc, currentKey) => {
                acc[currentKey] = false;
                return acc;
            }, {}),
            [key]: true
        }));
    }, []);


    const fetchAllUserBasedOnStatus = async (page, limit = rowsPerPage,) => {
        const res = await getAllUserBasedOnStatus(page, limit, status, search, selectedGender)
        // Store the data in session storage
        const filters = { page, limit, status, selectedGender }
        sessionStorage.setItem('verificationFilter', JSON.stringify(filters));

        setUserData(res?.data?.requestes.length === 0 ? false : res?.data?.requestes)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        setUserData([])
        fetchAllUserBasedOnStatus(pageNation.currentPage)
    }, [selectedGender, rowsPerPage, search, status])

    useEffect(() => {
        const storedFilters = sessionStorage.getItem('verificationFilter');
        if (storedFilters) {
            const filters = JSON.parse(storedFilters);

            setStatus(filters.status || 'requested');
            setSelectedGender(filters.selectedGender || '');
            setRowsPerPage(filters.limit || 10);
        }
    }, []);


    useEffect(() => {
        if (isVisible.table) {
            fetchAllUserBasedOnStatus(1)
        }
    }, [isVisible])


    const handleViewUser = () => {
        const id = encodeToBase64(selectedUser?.userId)
        const url = USERS.EDIT_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const genderOptions = [
        { label: 'optional', value: '' },
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
    ]


    return (
        <div className='flex flex-col h-screen'>
            {isConfirmationPopup.visibility &&
                <Suspense>
                    <ConfirmationPopup onClose={() => setIsConfirmationPopup({ visibility: false })} onConfirm={handleUpdateVerification}
                        text={`Are you sure you want to update the status to ${isConfirmationPopup.status}`}
                    />
                </Suspense>
            }

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Verification"}
                        subHeading={"user Verification"}
                        handleSearch={(value) => setSearch(value)}
                    />
                </div>
                {/* Table */}
                <div className="p-8">
                    <div className='flex justify-between'>
                        <div className="flex">
                            <button onClick={() => setStatus('requested')} className={`p-2 px-6 border ${status === 'requested' && 'bg-gray-300'}`}>Requested</button>
                            <button onClick={() => setStatus('verified')} className={`p-2 px-6 border-2 border-b-0 ${status === 'verified' && 'bg-gray-300'}`}>Verified</button>
                            <button onClick={() => setStatus('rejected')} className={`p-2 px-6 border-2 border-b-0 ${status === 'rejected' && 'bg-gray-300'}`}>Rejected</button>
                            <button onClick={() => setStatus('waitlist')} className={`p-2 px-6 border-2 border-b-0 ${status === 'waitlist' && 'bg-gray-300'}`}>Waitlist</button>
                        </div>
                        <div className="min-w-32">
                            <DropDown
                                height={'h-10'}
                                options={genderOptions}
                                selectedValue={selectedGender}
                                handleOnChange={(value) => setSelectedGender(value)}
                            />
                        </div>

                        <div className="flex items-center">
                            <label htmlFor="rowsPerPage" className="block text-sm font-medium text-gray-700">
                                Rows per page:
                            </label>
                            <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                value={rowsPerPage}
                                onChange={(e) => setRowsPerPage(e.target.value)}
                                className="block p-2 px-6 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div>
                    <>
                        {(userData?.length > 0 && isVisible.table) ?
                            <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchAllUserBasedOnStatus(value)} rowsPerPage={rowsPerPage} />
                            :
                            <>
                                {userData === false ?
                                    <div className="flex items-center justify-center w-full text-2xl text-black">No User Found</div>
                                    :
                                    <div className="flex items-center justify-center w-full text-2xl text-black">Loading the Data please wait...</div>
                                }
                            </>
                        }
                    </>
                </div>

                {!isVisible.table &&
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-white h-screen w-[90%]">

                            <div className="flex items-center justify-between">
                                <div className="flex gap-20 m-12">
                                    {headerContent?.map((item, index) => (
                                        <h1 key={index} onClick={() => handleChangeVisibility(item)} className={`${isVisible[item] ? 'font-semibold' : ''} text-lg text-gray-500 cursor-pointer`}>{item}</h1>
                                    ))}
                                </div>
                                <div>
                                    <button onClick={handleViewUser} className='p-2 px-4 text-white bg-brandRed me-3' >View User</button>

                                    <button onClick={() => handleChangeVisibility('table')} className="p-2 mr-2 text-xl leading-none text-black">
                                        &#x2715;
                                    </button>
                                </div>
                            </div>

                            {isVisible.NAME &&
                                <Suspense fallback={<div>loading...</div>}>
                                    <NameVerification data={selectedUser} />
                                </Suspense>
                            }
                            {isVisible.ID &&
                                <Suspense fallback={<div>loading...</div>}>
                                    <IdVerification data={selectedUser} />
                                </Suspense>
                            }
                            {isVisible.PHOTO &&
                                <Suspense fallback={<div>loading...</div>}>
                                    <PhotoVerification data={selectedUser} />
                                </Suspense>
                            }
                            {isVisible.STATUS &&
                                <Suspense fallback={<div>loading...</div>}>
                                    <StatusVerification data={selectedUser} changeView={() => handleChangeVisibility('table')} />
                                </Suspense>
                            }

                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Index