
import React from 'react';
import { ManagementSecMainHeader } from "components"
import Fast2sms from './components/fast2sms'
import RazorpayWebhookStatus from './components/razorpayWebhookStatus'


const Index = () => {

    return (
        <div>
            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Overview"}
                        subHeading={"Overview"}
                        btnText={"View"}
                    />
                </div>
                <div className="min-h-screen p-8 bg-gray-100">
                    {/* Fast 2 Sms Balance */}
                    <>
                        <div className="mb-2 font-semibold">Fast 2 Sms Balance</div>
                        <Fast2sms />
                    </>
                    {/* Razorpay Webhook Status */}
                    <div className='my-5'>
                        <div className="mb-2 font-semibold">Razorpay Webhook Status</div>
                        <RazorpayWebhookStatus />
                    </div>
                </div>

            </div>


        </div>
    );
};

export default Index;