import React, { addCaste, editCaste, fectchCastes } from 'apis/religionAndCaste'
import { ManagementSecMainHeader, TableWithoutPagination } from 'components'
import { Suspense, lazy, useEffect, useState } from 'react'
import { toast } from 'react-toastify'


const CreateAndEditReligionAndCast = lazy(() => import('../components/createAndEditPopup'))


function Index() {


    const [cast, setCast] = useState()
    const [showAddAndEditCast, setShowAddAndEditCast] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedCast, setSelectedCast] = useState('')

    const getAllCast = async () => {
        const res = await fectchCastes()
        setCast(res?.data)
    }

    useEffect(() => {
        getAllCast()
    }, [])

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Cast Name', accessor: 'name', },
        {
            Header: 'Religion Name', accessor: (row) => row.religion_id?.name || 'N/A', id: 'religionName'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <span className={`text-white p-2 rounded-3xl ${value ? 'bg-green-500' : 'bg-brandRed'}`}>
                    {value ? 'active' : 'inactive'}
                </span>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )
        }

    ];


    const handleEdit = (row) => {
        setSelectedCast(row)
        setShowAddAndEditCast(true)
        setIsEdit(true)
    }

    const handleSubmit = async (value) => {
        let res
        if (isEdit) {
            res = await editCaste(value, selectedCast?.id)
        } else {
            res = await addCaste(value)
        }
        if (res?.success) {
            toast(`${isEdit ? 'updated' : 'Created'} Cast`)
            setShowAddAndEditCast(false)
            setIsEdit(false)
            setSelectedCast('')
            getAllCast()
        }
    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedCast('')
        setShowAddAndEditCast(false)
    }

    return (

        <div className="flex flex-col h-full">
            {/* Popup for Adding and Editing Religion */}
            {showAddAndEditCast && (
                <div className="absolute inset-0 z-50 flex items-center justify-center h-full">
                    <Suspense fallback={<div>loading...</div>}>
                        <div className="fixed p-4 bg-white rounded-md shadow-lg w-fit">
                            <CreateAndEditReligionAndCast
                                data={selectedCast}
                                handleCancel={handleCancel}
                                handleSubmit={(value) => handleSubmit(value)}
                                isReligion={false}
                                isEdit={isEdit}
                            />
                        </div>
                    </Suspense>
                </div>
            )}

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Cast"}
                        btnText={"Add Cast"}
                        handleBtn={() => setShowAddAndEditCast(true)}
                    />
                </div>

                {/* Table */}
                <div className="w-full p-8">
                    {cast && <TableWithoutPagination columns={columns} data={cast} />}
                </div>
            </div>
        </div>

    )
}

export default Index