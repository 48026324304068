/* eslint-disable react/no-children-prop */
import { MainNavbar } from 'components';
import { getUnAuthEventBySlug } from 'apis/events';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { calculateTotalHours } from 'shared/dates';
import { capitalize } from 'shared/textConverter';
import { formatDate } from 'utils/dataConversion';
import { decodeFromBase64 } from 'utils/encodeAndDecode';

// Assets
import clock from 'assets/DetailPage/Clock.svg';
import ageIcon from 'assets/DetailPage/ageIcon.svg';
import frame2 from 'assets/DetailPage/frame2.svg';
import frame3 from 'assets/DetailPage/frame3.svg';
import singlesGray from 'assets/DetailPage/singlesGray.svg';

const Description = lazy(() => import("./components/description"))
const Comments = lazy(() => import("./components/comments"))
const TermsAndConditions = lazy(() => import("./components/termsAndConditions"))



function Index() {

    const { slug, comment } = useParams();
    const [data, setData] = useState(null)
    const [bookings, setBooking] = useState(null)
    const [currentTab, setCurrentTab] = useState(comment === 'true' ? 'comments' : 'description')

    const fetchEventBySlug = async () => {
        const res = await getUnAuthEventBySlug(decodeFromBase64(slug))
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { event, ...data } = res?.data ?? {};
        setBooking(data)
        setData(res?.data?.event)
    }


    useEffect(() => {
        fetchEventBySlug()
    }, [])

    return (

        <>

            {data &&
                <div className='flex flex-col items-center h-full bg-white'>
                    {/* Navbar Section */}
                    <div className='flex justify-center w-full lg:mb-16'>
                        <div className='w-full'>
                            <MainNavbar />
                        </div>
                    </div>

                    <div className="w-full">
                        <h1 className='text-start text-black font-medium text-2xl md:text-4xl mb-7 ml-[9%] lg:w-[50%]'>
                            {capitalize(data?.title)}
                        </h1>
                    </div>
                    {/* Header Section */}
                    <div className='flex flex-col items-center justify-between w-full px-4 mx-auto lg:px-0 lg:w-10/12 lg:flex-row'>

                        {/* Main Content Section */}
                        <div className='relative w-full lg:w-11/12 max-h-[440px] cursor-pointer'>
                            <span className={`absolute z-50 px-2 py-1 text-sm font-semibold text-white ${data?.mode === 'offline' ? 'bg-brandRed' : 'bg-green-500'}  rounded-md top-2 left-2`}> {data?.mode === 'offline' ? 'Offline' : 'Online'}</span>
                            <img className='object-cover w-full lg:w-11/12' src={data?.image} alt="Descriptive text" />
                        </div>


                        {/* Event Details Section */}
                        <div className='flex items-center w-full h-[440px] md:w-1/2 mx-auto lg:w-5/12 border border-gray-400 rounded text-black'>
                            <div className="w-[75%] mx-auto">
                                <h1 className='flex items-start pt-2 mb-5 text-xl font-medium mt-7'>
                                    {data?.title}
                                </h1>

                                <div className='flex items-start text-4xl font-semibold'>
                                    {data?.isFree ? 'Free' : `₹ ${data?.price}`}
                                </div>
                                <div className={`w-72 h-16 bg-brandRed text-white text-sm font-bold flex justify-center my-5 cursor-pointer`}>
                                    <p className='flex items-center justify-center'>
                                        Book
                                    </p>
                                </div>


                                <div className="flex flex-col space-y-2 w-72">
                                    {/* Hours */}
                                    <p className='flex items-center'>
                                        <img src={frame3} alt='coupleSquad' className='me-2' />
                                        {calculateTotalHours(data?.start_date, data?.start_time, data?.end_date, data?.end_time)} Hr
                                    </p>
                                    {/* RelationShip */}
                                    <p className='flex items-center'>
                                        <img src={data?.relationship_status === 'single' ? singlesGray : frame2} alt='coupleSquad' className='me-2' />
                                        {capitalize(data?.relationship_status)}
                                    </p>
                                    {/* Date */}
                                    <p className='flex items-center'>
                                        <img src={clock} alt='coupleSquad' className='me-2' />
                                        {formatDate(data?.start_date)} - {formatDate(data?.end_date)}
                                    </p>
                                    {/* Age */}
                                    <p className='flex items-center mt-3'>
                                        <img src={ageIcon} alt='coupleSquad' className='me-2' />
                                        {data?.age_from} - {data?.age_to}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col w-full px-4 mx-auto lg:w-10/12 lg:px-0 lg:flex-row'>
                        <div className='flex flex-col gap-4 lg:w-11/12'>
                            <div className='flex flex-row gap-4 mt-5 '>
                                {comment === 'false' &&
                                    <p className={`font-medium text-base ${currentTab === 'description' ? 'text-black' : 'text-base text-gray-500'} cursor-pointer`} onClick={() => setCurrentTab('description')}>Description</p>
                                }
                                {data?.enable_comments &&
                                    <p className={`font-medium text-base ${currentTab === 'comments' ? 'text-black' : 'text-base text-gray-500'} cursor-pointer`} onClick={() => setCurrentTab('comments')}>Comments</p>
                                }
                                {comment === 'false' &&
                                    <p className={`font-medium text-base ${currentTab === 'Terms_and_conditions' ? 'text-black' : 'text-base text-gray-500'} cursor-pointer`} onClick={() => setCurrentTab('Terms_and_conditions')}>Terms And Conditions</p>
                                }
                            </div>
                            {currentTab === 'description' && comment === 'false' &&
                                <Suspense children={<Description data={data} />} />
                            }
                            {currentTab === 'comments' &&
                                <Suspense children={<Comments data={data} isEdit={comment === 'false' ? false : decodeFromBase64(slug)} />} />
                            }
                            {currentTab === 'Terms_and_conditions' && comment === 'false' &&
                                <Suspense children={<TermsAndConditions data={bookings?.event_terms_and_conditions} />} />
                            }

                        </div>

                    </div>
                </div>
            }
        </>
    )
}

export default Index